/*
  *布局样式
  bill_yang 2021.06
*/
@import '../index.scss';

%headerIcon {
  width: vw(24);
  height: vw(24);
}

%headerImg {
  width: 100%;
  height: 100%;
}

// 头部雪碧图
@mixin headerBgIcon($width: 24px, $height: 24px) {
  width: $width;
  height: $height;
  background: url('../../imgs/webp/home-icon.webp') no-repeat top left;
  background-size: 216px 200px;
}

.layout {
  .portrait-name {
    display: flex;
    align-items: center;
    padding-left: vw(3);
    height: vw(38);
    border-radius: vw(29);
    color: #07080a;
    border: vw(2) solid #FFFFFF;
    cursor: pointer;
    position: relative;
    z-index: 102;

    button {
      width: 100%;
      cursor: pointer;
    }

    .user-name {
      color: #FFFFFF;
      font-size: vw(18);
      font-weight: 700;
      max-width: vw(220);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover .personal-menu {
      display: block;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: vw(10);
      position: absolute;
      left: 0;
      bottom: vw(-10);
    }

    img {
      width: vw(28);
      height: vw(28);
      border-radius: 50%;
    }

    span {
      font-size: vw(18);
      margin: 0 vw(16);
    }

    .personal-menu {
      width: vw(164);
      padding: 0 vw(16) vw(24) vw(16);
      position: absolute;
      left: 50%;
      margin-left: vw(-82);
      top: vw(40);
      border-radius: vw(16);
      text-align: center;
      font-size: vw(18);
      display: none;
      backdrop-filter: blur(40px);
      background: linear-gradient(180deg, #fff 35.94%, #eff6fa);
      box-shadow: -8rem 9rem 100rem rgba(59, 79, 102, 0.24) !important;
      color: #000;

      span {
        margin: vw(16) 0;
        display: block;
        height: vw(40);
        width: 100%;
        line-height: vw(40);
        border-radius: vw(8);

        &:hover {
          background: rgba(7, 19, 33, 0.08);
        }
      }

      p {
        height: vw(40);
        line-height: vw(40);
        margin: vw(16) 0;
        // border-top: vw(1) solid rgba(255, 255, 255, 0.5);
        font-weight: 400;
        position: relative;
        border-radius: vw(8);

        &.my-flights {
          &::before {
            content: '';
            display: block;
            width: 100%;
            border-top: vw(1) solid rgba(0, 0, 0, 0.2);
            position: absolute;
            left: 0;
            top: vw(-16);
          }
        }

        &.my-inquire {
          margin-top: 0;
          &::after {
            content: '';
            display: block;
            width: 100%;
            border-bottom: vw(1) solid rgba(0, 0, 0, 0.2);
            position: absolute;
            left: 0;
            bottom: vw(-16);
          }
        }

        &:hover {
          background: rgba(7, 19, 33, 0.08);
        }
      }

      ul {
        padding: vw(24) 0;
        border-bottom: vw(1) solid rgba(0, 0, 0, 0.2);

        button {
          display: block;
          width: 100%;
          height: vw(40);
          margin: 0 auto;
          background-size: contain;
          border-radius: vw(8);

          &:hover {
            background-color: rgba(7, 19, 33, 0.08);
          }

          &:nth-child(1) {
            background-image: url(../../imgs/svg/member-icon.svg);
          }

          &:nth-child(2) {
            background-image: url(../../imgs/svg/black-coupons.svg);
          }

          &:nth-child(3) {
            background-image: url(../../imgs/svg/black-addck.svg);
          }

          &:nth-child(4) {
            background-image: url(../../imgs/svg/black-password.svg);
          }

          & + button {
            margin-top: vw(24);
          }
        }
      }

      .sign-out {
        margin-top: vw(24);
        height: vw(38);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: vw(8);
        border: vw(1) solid #07080a;

        &:hover {
          background-color: #07080a !important;
          color: #fff !important;
          font-weight: 700;
        }
      }
    }


  }

  // 首页展示header
  .header-container {
    width: 100%;
    height: vw(64);
    background: rgba(0, 0, 0, 0);

    .w1500 {
      position: relative;
      width: vw(1500);
      height: 100%;
      margin: 0 auto;
      @include flex(space-between, center);
    }

    .header-left {
      width: vw(120);
      height: vw(33);

      img {
        cursor: pointer;
      }

      .logo {
        @extend %headerImg;
      }
    }

    .header-right {
      @include flex(space-between, center);

      .header-info {
        display: flex;
        align-items: center;
        > li {
          margin-right: vw(16);

          &:last-child {
            margin-right: 0;
          }

          a {
            @include flex(flex-start, center);
            color: rgba(0, 0, 0, 0.85);
          }
        }

        .header-code {
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;

          .code-box {
            width: vw(320);
            height: vw(320);
            background: #ffffff;
            border-radius: vw(24);
            position: absolute;
            top: vw(60);
            left: vw(-147);
            z-index: 102;
            display: none;

            &::before {
              content: '';
              display: block;
              width: 80px;
              height: 2px;
              background: #0063db;
              margin: vw(30) auto vw(16);
            }

            .QRcode {
              width: vw(224);
              height: vw(224);
              border-radius: vw(4);
              margin: 0 auto vw(4);
            }

            > p {
              font-weight: normal;
              font-size: vw(18);
              line-height: vw(22);
              color: #07080a;
              text-align: center;
            }

            &.active {
              display: block;
            }
          }
        }

        .code-mask {
          position: fixed;
          top: vw(64);
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.48);
          display: none;

          &.active {
            display: block;
          }
        }
      }

      .header-share {
        @include flex(flex-start, center);

        > li {
          @extend %headerIcon;
          @include headerBgIcon();
          margin-right: vw(24);
          cursor: pointer;

          &:nth-child(1) {
            background-position: 0 0;

            &:hover {
              background-position: 0 -48px;
            }
          }

          &:nth-child(2) {
            background-position: -48px 0;

            &:hover {
              background-position: -48px -48px;
            }
          }

          &:nth-child(3) {
            background-position: -96px 0;

            &:hover {
              background-position: -96px -48px;
            }
          }

          &:nth-child(4) {
            background-position: -144px 0;

            &:hover {
              background-position: -144px -48px;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          > img {
            @extend %headerImg;
          }
        }
      }

      .header-sign {
        @include flex(flex-start, center);
        margin-left: vw(40);

        .sign {
          @include flex(center, center);
          width: vw(115);
          height: vw(38);
          font-weight: 700;
          font-size: vw(18);
          text-transform: uppercase;
          color: #FFFFFF;
          border: vw(2) solid #FFFFFF;
          box-sizing: border-box;
          border-radius: vw(48);
          cursor: pointer;

          &:hover {
            color: #fff;
            background: #0063db;
            border: vw(2) solid transparent;
          }
        }
      }
    }
  }

  .header {
    height: vw(72);
    position: fixed;
    top: vw(64);
    left: 0;
    width: 100%;
    z-index: 102;
    color: #fff;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      // filter: blur(2px);
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      backdrop-filter: blur(5px);
    }

    .logo {
      width: vw(145);
      cursor: pointer;
    }
    .header-right {
      display: flex;
      align-items: center;

      .sign {
        width: vw(130);
        height: vw(46);
        background: linear-gradient(94.57deg, #0063db 0%, #17c7ff 100%);
        font-size: vw(18);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: vw(48);
        cursor: pointer;
        font-weight: 700;
      }
    }
  }

  // header-nav
  .header-nav {
    height: 100%;
    @include flex(center, center);
    z-index: 11;
    > li {
      height: 100%;
      line-height: vw(64);
      position: relative;
      margin-right: vw(56);

      &:last-child {
        margin-right: 0;
      }

      > a,
      > span {
        font-weight: 400;
        font-size: vw(18);
        color: #ffffff;
        cursor: pointer;

        &:hover {
          color: #0063DB;
        }
      }
      &.header-nav-parent {
        > span {
          > a {
            color: #fff;
            &:hover {
              color: #0063DB;
            }
          }
          > svg {
            transition: all 0.24s ease-in-out;
          }
        }
        &:hover {
          > span {
            > svg {
              transform: rotate(180deg);
            }
          }

          .header-nav-children {
            display: block;
          }
        }
      }
    }

    .header-nav-children {
      display: none;
      min-width: vw(170);
      position: absolute;
      left: vw(-10);
      top: vw(64);
      background: rgba(13, 17, 35, 0.85);
      box-shadow: 0px vw(4) vw(40) rgba(101, 131, 168, 0.24);
      font-size: vw(18);
      line-height: vw(22);
      font-weight: 400;
      padding: 0 vw(24);

      &.about-airacer {
        min-width: vw(282);
        left: vw(-19);
      }

      > li {
        padding: vw(16) 0;
        border-bottom: vw(1) solid rgba(255, 255, 255, 0.2);

        &:last-child {
          border-bottom: vw(1) solid transparent;
        }

        &:hover {
          a {
            color: #0063DB;
          }
        }

        a {
          display: block;
          color: #ffffff;
        }
      }
    }
  }

  // 内容
  // 登录
  .login-form {
    padding: vw(48) vw(104);
    .sel-err {
      height: vw(152) !important;
      padding: vw(16);
      background-color: #ecedee;
      top: vw(60);
      p {
        margin-top: vw(8);
      }
    }
    .ant-input-password {
      .ant-input-password-icon {
        &.anticon-eye {
          background-image: url(../../imgs/svg/sign_show_pw.svg);
        }

        &.anticon-eye-invisible {
          background-image: url(../../imgs/svg/sign_hidden_pw.svg);
        }
      }
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      // border-right-width: vw(2) !important;
      border-color: #fff !important;
      background: transparent;
    }

    input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.2);
    }

    input::-moz-input-placeholder {
      color: rgba(255, 255, 255, 0.2);
    }

    input::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.2);
    }

    .login-nav {
      font-size: vw(36);
      font-weight: 700;
      display: flex;

      li {
        flex: 1;
        text-align: center;
        color: rgba(255, 255, 255, 0.2);
        border-bottom: vw(1) solid rgba(255, 255, 255, 0.2);
        padding-bottom: vw(16);
        cursor: pointer;

        &.active {
          color: #fff;
          border-bottom: vw(2) solid rgba(255, 255, 255, 1);
        }
      }
    }

    .public-form {
      position: relative;

      .form-title {
        padding: vw(24) 0 vw(32) 0;
        text-align: center;
        font-size: vw(18);
      }

      .resend-code {
        width: vw(146);
        height: vw(52);
        border-left: 2px solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: vw(1.6);
        top: vw(2);
        z-index: 99;
        cursor: pointer;
        font-weight: 700;
        font-size: vw(18);
        border-radius: 0 vw(6) vw(6) 0;

        &.ban {
          background-color: rgba(255, 255, 255, 0.2);
          border: none;
          color: #fff;

          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            color: #fff;
          }
        }

        &:hover {
          background-color: #fff;
          color: $bg-flights;
        }
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none !important;
        border-color: #fff;
        border-right-width: vw(2) !important;
      }

      .ant-select-selector {
        width: vw(200);
        height: vw(56);
        border-radius: vw(16);
        border: vw(2) solid rgba(255, 255, 255, 0.2);
        background-color: transparent !important;
        padding-left: vw(40);
        position: relative;
        border-right-width: vw(2) !important;

        &::before {
          content: '+';
          display: block;
          color: #fff;
          position: absolute;
          left: vw(24);
          top: 50%;
          font-size: vw(18);
          margin-top: vw(-14);
        }

        .ant-select-selection-search-input {
          padding-left: vw(24);
        }

        .ant-select-selection-item,
        .ant-select-selection-search-input,
        .ant-select-selection-placeholder {
          color: #fff;
          height: vw(52);
          line-height: vw(52);
          font-size: vw(18);
        }
      }

      .ant-select-arrow {
        width: vw(20);
        height: vw(20);
        top: 50%;
        margin-top: vw(-10);
      }

      .anticon-search,
      .anticon-down {
        font-size: vw(18);
        color: rgba(255, 255, 255, 0.8);
      }

      .ant-input-affix-wrapper {
        height: vw(56);
        border-radius: vw(8);
        border: vw(2) solid rgba(255, 255, 255, 0.2);
        background-color: transparent;
        padding-left: vw(24);

        .ant-input-prefix {
          width: vw(32);
          margin-right: vw(16);
          font-size: vw(20);

          .anticon-mail {
            color: $bgck-light-blue;
          }

          .anticon-user {
            color: $bgck-light-orange;
          }

          .anticon-phone {
            color: $bgck-light-yellow;
            transform: rotate(90deg);
          }

          .anticon-lock {
            color: $bgck-light-green;
          }
        }

        .ant-input-suffix {
          width: vw(24);
          font-size: vw(20);
          margin: 0;

          .anticon-close-circle {
            font-size: vw(18);
            margin: 0;
          }

          .anticon {
            color: rgba(255, 255, 255, 0.6);
          }
        }

        .ant-input {
          background-color: transparent;
          color: #fff;
          font-size: vw(18);
        }
      }

      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused {
        box-shadow: none;
        border-color: #fff;
        border-right-width: vw(2) !important;
      }

      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-right-width: vw(2) !important;
      }

      .ant-select-selection-placeholder {
        color: rgba(255, 255, 255, 0.2) !important;
      }

      .ant-form-item-has-error .ant-input,
      .ant-form-item-has-error .ant-input-affix-wrapper,
      .ant-form-item-has-error .ant-input:hover,
      .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        border-color: $bgck-light-red;
      }

      .ant-btn {
        width: 100%;
        height: vw(60);
        border-radius: vw(16);
        font-size: vw(24);
        font-weight: 700;
        background: url(../../imgs/webp/seach_bg.webp) no-repeat;
        background-size: cover;
        background-position: center;
        background-color: transparent;
        border: none;
      }

      .two-item {
        display: flex;

        .ant-form-item {
          & + .ant-form-item {
            margin-left: vw(24);
          }
        }

        .full-width {
          width: 100%;
          margin-left: vw(24);

          .ant-form-item {
            width: 100%;
          }
        }
      }

      &.signin {
        .forgot-password {
          font-size: vw(18);
          text-align: right;
          color: #fff;
          margin-bottom: vw(32);
          button {
            font-weight: 700;
            cursor: pointer;
          }
        }

        .or {
          font-size: vw(24);
          color: rgba(255, 255, 255, 0.8);
          padding: vw(24) 0 vw(40) 0;
          text-align: center;
          position: relative;

          &::before {
            content: '';
            display: block;
            width: vw(268);
            height: vw(1);
            background-color: rgba(255, 255, 255, 0.2);
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: vw(-4);
          }

          &::after {
            content: '';
            display: block;
            width: vw(268);
            height: vw(1);
            background-color: rgba(255, 255, 255, 0.2);
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: vw(-4);
          }
        }

        .other-login {
          display: flex;

          p {
            flex: 1;
            height: vw(56);
            border-radius: vw(8);
            border: vw(2) solid rgba(255, 255, 255, 0.2);
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(255, 255, 255, 0.2);
            font-size: vw(18);
            cursor: pointer;
            position: relative;

            &:hover {
              color: #fff;
              border: vw(2) solid #fff;
            }

            button {
              width: 100%;
              height: 100%;
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }

            i {
              width: vw(24);
              height: vw(24);

              &.google {
                background-image: url(../../imgs/svg/icon_google.svg);
              }

              &.facebook {
                background-image: url(../../imgs/svg/icon_Facebook_small.svg);
              }
            }

            span {
              margin-left: vw(16);

              + span {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                margin-left: 0;
              }
            }

            .anticon {
              color: rgba(255, 255, 255, 0.8);
            }

            & + p {
              margin-left: vw(16);
            }
          }
        }
      }

      &.wx {
        .form-title {
          font-size: vw(24);
        }
      }

      .check-terms {
        margin-bottom: vw(16);
        display: flex;
        align-items: center;

        i {
          display: block;
          width: vw(24);
          height: vw(24);
          border-radius: vw(4);
          border: vw(2) solid rgba(255, 255, 255, 0.2);
          background-color: transparent;
          cursor: pointer;
          flex-shrink: 0;

          &.active {
            border-radius: vw(4);
            background-image: url(../../imgs/svg/selected.svg);
            border: none;
          }
        }

        p {
          font-size: vw(14);
          color: rgba(255, 255, 255, 0.8);
          margin-left: vw(16);
          &.text {
            margin-left: 0;
          }

          a {
            font-weight: 700;
            color: #fff;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .forgot-password-form {
    text-align: center;
    padding: vw(120) 0;

    .back-page {
      position: absolute;
      left: vw(40);
      top: vw(40);
      font-size: vw(18);
      display: flex;
      align-items: center;
      font-weight: 700;
      cursor: pointer;

      > i {
        width: vw(40);
        height: vw(40);
        background-image: url(../../imgs/svg/down_j.svg);
        transform: rotate(90deg);
      }
    }

    > p {
      font-weight: 700;
      font-size: vw(36);
      white-space: nowrap;
      line-height: vw(40);
    }

    > span {
      font-size: vw(18);
      display: block;
      margin: vw(16) 0 vw(40) 0;
    }

    .ant-btn {
      margin-top: vw(16);
    }
  }
}

.marquee {
  position: relative;
  width: vw(1456);
  height: 100%;
  line-height: vw(48);
  overflow: hidden;
  font-size: vw(18);
  margin-right: vw(128);
  margin-left: vw(40);
  cursor: pointer;
}

.marquee .wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: cssmarqueeWrapper 15s linear infinite;
}

.marquee .marqueeWords {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  font-weight: 500;
  color: #fff;
  margin: 0;
  text-align: left;
  white-space: nowrap;
  /* no wrap */
  transform: translateX(0);
  animation: cssmarquee 15s linear infinite;

  span {
    font-weight: 700;
    color: $bgck-light-yellow;
  }
}

@keyframes cssmarqueeWrapper {
  0% {
    transform: translateX(100%);
    /* parent 100% */
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes cssmarquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    /* words -100% */
  }
}

.telephone_dialog {
  width: 100%;
  height: 100%;
  background: rgba(13, 17, 35, 0.48);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog_content {
    position: relative;
    width: vw(784);
    height: vw(640);
    padding: vw(80) vw(24) vw(40);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 52.87%, rgba(0, 99, 219, 0.16) 100%),
      #ffffff;
    border-radius: vw(16);

    .public-title {
      position: relative;
      text-align: center;
      color: #07080a;
      padding: 0;

      > img {
        width: vw(34);
        display: inline-block;
        margin-right: vw(8);
        position: absolute;
        margin-left: vw(-42);
        margin-top: vw(6);
      }

      > span {
        font-weight: 700;
        font-size: vw(36);
        line-height: vw(44);
        text-transform: none;
        vertical-align: middle;
      }

      > p {
        font-weight: 400;
        font-size: vw(18);
        line-height: vw(26);
        text-align: center;
        margin-top: vw(24);
        color: #07080a;
      }
    }

    .close {
      position: absolute;
      top: vw(16);
      right: vw(16);
      width: vw(24);
      cursor: pointer;
    }

    .code-phone {
      width: vw(400);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      margin: vw(40) auto 0;

      .country-code {
        bottom: vw(-140);
        width: vw(400);
        padding: vw(16) vw(8) vw(16) vw(16);

        &::before {
          display: none;
        }

        ul {
          height: vw(230);

          li {
            margin-right: vw(8);
          }

          &::-webkit-scrollbar {
            display: block;
            width: vw(4);
          }
          &::-webkit-scrollbar-track {
            background: #ecedee;
            border-radius: vw(2);
            box-shadow: none;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.2);
            border-radius: vw(2);
            box-shadow: none;
            width: vw(4);
            height: vw(41);
          }
        }

        input {
          width: vw(364);

          &::-webkit-input-placeholder {
            font-weight: 400;
            font-size: vw(12);
            line-height: vw(24);
            color: rgba(0, 0, 0, 0.2);
          }
        }
      }

      .code-width {
        margin: 0;
        width: vw(208);

        svg {
          color: #07080a;
        }

        .ant-input-affix-wrapper {
          width: 100%;
          height: vw(56);
          color: rgba(0, 0, 0, 0.85);

          input {
            line-height: vw(20);
          }
        }
      }

      .phone-width {
        position: relative;
        width: vw(400);

        .ant-form-item-has-error {
          .ant-form-item-control-input-content {
            border-color: $bgck-light-red;
          }
        }

        .ant-form-item-control-input-content {
          display: flex;
          align-items: center;
          width: 100%;
          height: vw(56);
          border: vw(1.6) solid rgba(0, 0, 0, 0.2);
          border-radius: vw(8);
          justify-content: flex-end;

          > input {
            max-width: vw(297);
            height: 100%;
            border: none;
            background-color: transparent;
            box-shadow: none;
            padding: 0 vw(16);
            font-size: vw(16);

            &:focus,
            &:hover {
              border: none;
              background-color: transparent;
              box-shadow: none;
            }
          }
          &:hover {
            border: vw(1) solid #07080a;
            background-color: transparent;
            box-shadow: none;
          }
        }
        .code {
          position: absolute;
          left: 0;
          top: vw(16);
          display: flex;
          align-items: center;
          padding-left: vw(16);
          color: #07080a;
          z-index: 2;

          > .flag {
            position: relative;
            display: flex;
            align-items: center;

            img {
              width: vw(30);
              height: vw(20);
              margin-right: vw(8);
            }

            &::after {
              content: '';
              display: block;
              width: vw(15);
              height: vw(15);
              background-image: url(../../imgs/svg/black-down-sanjiao.svg);
              background-repeat: no-repeat;
              background-size: contain;
            }
          }

          span {
            display: inline-block;
            height: 100%;
            padding-left: vw(16);
            font-size: vw(16);
          }
        }
      }

      .ant-form-item-has-error {
        .ant-input-affix-wrapper,
        .ant-input-affix-wrapper-focused {
          box-shadow: none;
          border: vw(2) solid #ff4d4f !important;
          border-color: #ff4d4f !important;
        }

        .ant-form-item-explain-error {
          font-weight: 400;
        }
      }

      .ant-input-affix-wrapper {
        border: vw(2) solid rgba(0, 0, 0, 0.2);
        border-radius: vw(8);
        padding: vw(6) vw(11) vw(6) vw(28);
        background-color: transparent;

        &:not(.ant-input-affix-wrapper-disabled):hover {
          border: vw(2) solid rgba(0, 0, 0, 0.2);
        }

        input.ant-input {
          background-color: transparent;
          font-size: vw(20);
        }

        &.ant-input-affix-wrapper-focused {
          background-color: #fff;
        }
      }

      .ant-form-item-control-input-content {
        text-align: center;
      }

      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: rgba(0, 0, 0, 0.2) !important;
      }
    }

    .submit_btn {
      display: block;
      border: 0;
      outline: none;
      width: vw(366);
      height: vw(56);
      border-radius: vw(8);
      font-weight: 700;
      font-size: vw(24);
      line-height: vw(29);
      text-align: center;
      color: #ffffff;
      background-image: url(../../imgs/svg/inquire_bg.svg);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: vw(106);
      cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 16px rgb(72 84 115 / 40%);
      }
    }
  }

  .telephone_content {
    height: vw(454);
  }

  .inquire_success {
    .view_more {
      font-weight: 400;
      font-size: vw(18);
      line-height: vw(26);
      text-align: center;
      text-decoration: underline;
      color: #07080a;
      position: absolute;
      bottom: vw(40);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .add_info_content {
    width: vw(640);
    height: vw(682);
    padding: vw(56) vw(40);

    .public-title {
      > p {
        margin-top: vw(16);
      }
    }

    .info_form {
      width: vw(400);
      margin: 0 auto;

      .login_btn {
        display: block;
        width: vw(160);
        height: vw(45);
        background: rgba(7, 19, 33, 0.08);
        border-radius: vw(42);
        font-weight: 700;
        font-size: vw(24);
        line-height: vw(45);
        color: #07080a;
        margin: vw(16) auto 0;
        border: none;
        outline: none;
        cursor: pointer;
      }

      .or {
        position: relative;
        font-size: vw(16);
        line-height: vw(19);
        color: #07080a;
        text-align: center;
        padding: vw(16) 0;

        &::before,
        &::after {
          content: '';
          display: block;
          width: vw(173);
          height: vw(1);
          background-color: rgba(7, 19, 33, 0.08);
          position: absolute;
          top: 50%;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }

      .info_content {
        li {
          position: relative;

          & + li {
            margin-top: vw(16);
          }

          .error-source {
            .choose_name {
              border: vw(1) solid red;
              border-radius: vw(8);
            }
          }

          .double {
            display: flex;
            position: relative;
          }

          .input-text {
            position: relative;
            display: flex;
            align-items: center;
            flex: 1;

            > input {
              outline: none;
              width: 100%;
              height: vw(56);
              display: flex;
              align-items: center;
              min-height: auto;
              padding: 0 vw(24) 0 vw(64);
              margin: 0;
              border-radius: vw(8);
              background-color: transparent;
              border: vw(1) solid rgba(0, 0, 0, 0.2);
              box-shadow: none;
              color: #07080a;
              font-size: vw(18);

              &.error {
                border-color: $bgck-light-red;
              }

              &::placeholder {
                font-size: vw(18);
                font-weight: 400;
                color: rgba(7, 19, 33, 0.2);
              }
              &:hover,
              &:focus {
                border-color: #07080a;
              }
            }

            .public-icon {
              background-size: cover;
              width: vw(24);
              height: vw(24);
              position: absolute;
              top: 50%;
              margin-top: vw(-12);
              left: vw(16);

              &.name-icon {
                background-image: url(../../imgs/svg/icon_name.svg);
              }

              &.email-icon {
                background-image: url(../../imgs/svg/icon_email.svg);
              }
            }

            .operation-icon {
              background-size: cover;
              width: vw(24);
              height: vw(24);
              position: absolute;
              top: 50%;
              margin-top: vw(-12);
              right: vw(16);

              &.clear-icon {
                background-image: url(../../imgs/svg/clear.svg);
              }
            }
          }

          .error-text {
            width: 100%;
            color: $bgck-light-red;
            font-size: vw(9);
            line-height: vw(12);
            position: absolute;
            bottom: vw(-13);
            left: 0;
          }
          .code {
            width: vw(400);
            height: vw(56);
            //border: 1.6px solid rgba(0, 0, 0, 0.2);
            //border-radius: vw(8);
            border: vw(1) solid rgba(0, 0, 0, 0.2);
            border-radius: vw(8);
            display: flex;
            align-items: center;
            margin-bottom: vw(16);
            > div {
              display: flex;
              align-items: center;
            }
            &:hover {
              border-color: #07080a;
            }
            &.error {
              border-color: $bgck-light-red;
            }
            .flag {
              display: block;
              width: vw(30);
              height: vw(20);
              margin-left: vw(16);
              margin-right: vw(8);
            }
            .sanjiao {
              width: 16px;
              height: 15px;
            }
            span {
              margin: 0 vw(16);
            }
            input {
              outline: none;
              width: 100%;
              height: vw(56);
              display: flex;
              align-items: center;
              min-height: auto;
              margin: 0;
              border-radius: vw(8);
              background-color: transparent;
              border: none;
              box-shadow: none;
              color: #07080a;
              font-size: vw(18);

              &.error {
                border-color: $bgck-light-red;
              }

              &::placeholder {
                font-size: vw(18);
                font-weight: 400;
                color: rgba(7, 19, 33, 0.2);
              }
              &:hover,
              &:focus {
                border-color: #07080a;
              }
            }
          }
          .country-code {
            width: 100%;
          }
        }
      }
    }

    .submit_btn {
      width: vw(400);
      bottom: vw(56);
    }
  }
}